"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Button, Input, Typography } from "@patients-ui/components";
import { ShieldCheckmark, Question } from "@patients-ui/icons";
import { matchPath } from "react-router-dom";
import { Turnstile } from "@marsidev/react-turnstile";
import styles from "./styles.module.scss";
import Alert from "../Alert";
import { SimpleHeader } from "~/views/partials/SimpleHeader";
import { usePrescriptionContext } from "../../../PrescriptionContext";
import { UNLOCK_HELPER_LINK, openChat } from "~/utils/helpers";
import { getSessionItem, setSessionItem } from "~/utils/storage";
import { xRay } from "~/utils/tracking";
import { accessibilityPhone } from "~/utils/accessibility";
import { useModalsContext } from "~/views/contexts/ModalsContext";
const AuthModalContent = () => {
  const {
    handleSubmit,
    register,
    setFocus,
    formState: {
      isSubmitting,
      errors
    }
  } = useForm({
    defaultValues: {
      char1: "",
      char2: "",
      char3: "",
      char4: ""
    }
  });
  const {
    prescription,
    hasError,
    isPreviewMode,
    setTurnstileToken
  } = usePrescriptionContext();
  const {
    toggleAlert,
    updateTermsCallback,
    unlockPrescriptionCallback
  } = useModalsContext();
  const maskedPhone = useMemo(() => prescription.patient.phone && prescription.patient.phone.replace(/(\d{2})(\d{5})(.{4})/g, "$1 $2-$3"), [prescription.patient.phone]);
  const isSentViaQrCode = useMemo(() => prescription.sent_via === "qrcode", [prescription.sent_via]);
  const isSentViaEmail = useMemo(() => prescription.sent_via === "email", [prescription.sent_via]);
  const onSubmit = (body) => __async(void 0, null, function* () {
    const code = Object.values(body).join("");
    const privacyForm = getSessionItem("privacy_form");
    const downloadPdf = getSessionItem("download_pdf");
    if (privacyForm) {
      yield updateTermsCallback(code);
      return;
    }
    if (downloadPdf) {
      const isPrescriptionRoute = matchPath({ path: "/p/:token/receita" }, window.location.pathname);
      xRay.sendEvent({
        name: "Download PDF Submit Click",
        screen: isPrescriptionRoute ? "assistant_home" : "direct_sale_drugs_listing",
        properties: {
          is_unlock_by_phone: true
        }
      });
    }
    yield unlockPrescriptionCallback(code);
  });
  const onKeyDown = useCallback((e) => {
    const {
      which,
      keyCode,
      code,
      target
    } = e;
    if ([which, keyCode].includes(8) || code === "Backspace") {
      if (!target.value) {
        if (target.name === "char2") {
          setFocus("char1");
        } else if (target.name === "char3") {
          setFocus("char2");
        } else if (target.name === "char4") {
          setFocus("char3");
        }
      }
      return;
    }
    if (target.value) {
      if (target.name === "char1") {
        setFocus("char2");
      } else if (target.name === "char2") {
        setFocus("char3");
      } else if (target.name === "char3") {
        setFocus("char4");
      }
    }
  }, [setFocus]);
  const onClose = useCallback((e) => {
    e.preventDefault();
    toggleAlert();
  }, [toggleAlert]);
  const isSentViaEmailOrQRCode = isSentViaQrCode || isSentViaEmail;
  const phoneAccessibilityMessage = !isSentViaEmailOrQRCode && { "aria-label": `Digite os 4 \xFAltimos d\xEDgitos do celular em que voc\xEA recebeu a receita ${accessibilityPhone(maskedPhone)}` };
  return /* @__PURE__ */ jsxs("div", { className: "flex flex-col h-full justify-between", children: [
    /* @__PURE__ */ jsx(SimpleHeader, { handleClick: onClose, label: "Autentica\xE7\xE3o da receita", className: "shadow", children: /* @__PURE__ */ jsx("div", { className: "flex flex-row gap-2", children: /* @__PURE__ */ jsx("button", { onClick: openChat, disabled: isPreviewMode, "aria-label": "Abrir chat para obter suporte", children: /* @__PURE__ */ jsx(Question, { className: "text-primary-600" }) }) }) }),
    /* @__PURE__ */ jsxs("div", { className: "px-4", children: [
      /* @__PURE__ */ jsxs("div", { className: "flex flex-col gap-4 items-center", children: [
        /* @__PURE__ */ jsx("figure", { className: "w-20 h-20 rounded-full border-8 border-neutral-100 bg-neutral-200 flex items-center justify-center", children: /* @__PURE__ */ jsx(ShieldCheckmark, { width: "32", height: "32", className: "text-neutral-600" }) }),
        /* @__PURE__ */ jsx("div", { className: "inline", children: /* @__PURE__ */ jsx(Typography, __spreadProps(__spreadValues({ variant: "paragraph", spacing: "distant", weight: "400", className: "text-center" }, phoneAccessibilityMessage), { children: isSentViaEmailOrQRCode ? "Digite o c\xF3digo enviado previamente pelo aplicativo de consulta, e-mail ou receita impressa." : /* @__PURE__ */ jsxs(Fragment, { children: [
          "Digite os ",
          /* @__PURE__ */ jsx("strong", { children: "4 \xFAltimos d\xEDgitos" }),
          " do celular em que voc\xEA recebeu a receita ",
          maskedPhone ? `(${maskedPhone})` : ""
        ] }) })) })
      ] }),
      /* @__PURE__ */ jsxs("form", { onSubmit: handleSubmit(onSubmit), "aria-hidden": "false", className: "flex flex-col", children: [
        /* @__PURE__ */ jsxs("div", { className: styles.inputGroup, children: [
          /* @__PURE__ */ jsx(
            Input,
            __spreadProps(__spreadValues({
              "aria-label": "Primeiro d\xEDgito do c\xF3digo",
              inputMode: "numeric",
              type: "tel",
              error: Boolean(errors.char1),
              disabled: isSubmitting,
              className: "h-12 w-12 p-0 text-center",
              maxLength: 1
            }, register("char1", {
              required: true
            })), {
              onKeyDown
            })
          ),
          /* @__PURE__ */ jsx(
            Input,
            __spreadProps(__spreadValues({
              "aria-label": "Segundo d\xEDgito do c\xF3digo",
              inputMode: "numeric",
              type: "tel",
              error: Boolean(errors.char2),
              disabled: isSubmitting,
              className: "h-12 w-12 p-0 text-center",
              maxLength: 1
            }, register("char2", {
              required: true
            })), {
              onKeyDown
            })
          ),
          /* @__PURE__ */ jsx(
            Input,
            __spreadProps(__spreadValues({
              "aria-label": "Terceiro d\xEDgito do c\xF3digo",
              inputMode: "numeric",
              type: "tel",
              error: Boolean(errors.char3),
              disabled: isSubmitting,
              className: "h-12 w-12 p-0 text-center",
              maxLength: 1
            }, register("char3", {
              required: true
            })), {
              onKeyDown
            })
          ),
          /* @__PURE__ */ jsx(
            Input,
            __spreadProps(__spreadValues({
              "aria-label": "Quarto d\xEDgito do c\xF3digo",
              inputMode: "numeric",
              type: "tel",
              error: Boolean(errors.char4),
              disabled: isSubmitting,
              className: "h-12 w-12 p-0 text-center",
              maxLength: 1
            }, register("char4", {
              required: true
            })), {
              onKeyDown
            })
          )
        ] }),
        hasError && /* @__PURE__ */ jsx(Alert, {}),
        /* @__PURE__ */ jsx("div", { className: styles.turnstile, children: /* @__PURE__ */ jsx("div", { children: /* @__PURE__ */ jsx(
          Turnstile,
          {
            siteKey: process.env.REACT_APP_TURNSTILE_SITEKEY,
            onSuccess: (turnstileToken) => {
              setSessionItem("turnstile_token", turnstileToken);
              setTurnstileToken(turnstileToken);
            },
            options: { theme: "light" }
          }
        ) }) }),
        /* @__PURE__ */ jsx("div", { className: "flex justify-end", children: /* @__PURE__ */ jsx(Button, { size: "sm", title: "Confirmar", type: "submit", className: "w-fit", isLoading: isSubmitting, children: "Confirmar" }) })
      ] })
    ] }),
    /* @__PURE__ */ jsxs("div", { className: "p-4", children: [
      /* @__PURE__ */ jsx(Typography, { variant: "detail", spacing: "distant", weight: "400", className: "text-center text-neutral-500 mb-4", children: "Esta etapa de seguran\xE7a protege os dados do titular dessa receita digital." }),
      /* @__PURE__ */ jsxs(Typography, { variant: "detail", spacing: "distant", weight: "400", className: "text-center text-neutral-500", "aria-label": "Est\xE1 com d\xFAvidas? clique no link \xE0 direita para acessar nossa central de ajuda", children: [
        "Est\xE1 com d\xFAvidas?",
        " ",
        /* @__PURE__ */ jsx(
          "a",
          {
            href: UNLOCK_HELPER_LINK,
            target: "_blank",
            rel: "noopener noreferrer",
            className: "text-primary-600 underline hover:text-primary-800",
            children: "Acesse nossa central de ajuda"
          }
        )
      ] })
    ] })
  ] });
};
export default AuthModalContent;
